import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../components/Layout';
import InterestedBuyers from '../components/InterestedBuyers';

import {
  Row,
  Row8Col,
  Row10Col,
  BackpageBannerWrapper,
  BackpageBanner,
  PageTitle,
  MainPageContent,
} from '../components/CustomComponents';

const Details = styled.div`
  p {
    margin-bottom: 0rem;
  }

  a {
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 769px) {
    flex: 1 1 65%;
  }
`;

const FeaturedImageWrapper = styled.div`
  width: 100%;

  border-radius: 2px;
  overflow: hidden;

  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

  img {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 769px) {
    flex: 1 1 32%;
  }
`;

const HomeInfo = styled.div`
  @media only screen and (min-width: 769px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export default class SingleHome extends Component {
  render() {
    const { location } = this.props;
    const { html } = this.props.data.markdownRemark;
    const {
      title,
      price,
      date_listed,
      date_available,
      hero_image,
      realtor_name,
      realtor_email,
      realtor_phone,
      secondary_realtor_phone,
      alt_realtor_name,
      alt_realtor_email,
      sale_or_lease,
      rental_deposit,
      featured_image,
    } = this.props.data.markdownRemark.frontmatter;

    const cleanRealtorPhone = realtor_phone
      ? realtor_phone.replace(/[^\d]/g, '')
      : null;

    const cleanSecondaryRealtorPhone = secondary_realtor_phone
      ? secondary_realtor_phone.replace(/[^\d]/g, '')
      : null;

    const realtorInformation = (
      <div>
        <h2 style={{ marginTop: '1rem' }}>Contact Information</h2>
        {realtor_name ? (
          <p>
            <strong>{realtor_name}</strong>
          </p>
        ) : null}

        {realtor_email ? (
          <p>
            <a href={`mailto:${realtor_email}`}>{realtor_email}</a>
          </p>
        ) : null}

        {cleanRealtorPhone ? (
          <p>
            <a href={`tel:${cleanRealtorPhone}`}>{realtor_phone}</a>
          </p>
        ) : null}

        {cleanSecondaryRealtorPhone ? (
          <p>
            <a href={`tel:${cleanSecondaryRealtorPhone}`}>
              {secondary_realtor_phone}
            </a>
          </p>
        ) : null}

        {alt_realtor_name || alt_realtor_email ? <br /> : null}

        {alt_realtor_name ? (
          <p>
            <strong>{alt_realtor_name}</strong>
          </p>
        ) : null}

        {alt_realtor_email ? (
          <p>
            <a href={`mailto:${alt_realtor_email}`}>{alt_realtor_email}</a>
          </p>
        ) : null}
      </div>
    );

    const pageMetaDescription = `View the ${title} unit listed at ${price}.${
      realtor_name
        ? ` Contact ${realtor_name} ${
            realtor_email ? ` at ${realtor_email}` : ''
          } for more information`
        : ''
    }`;

    return (
      <Layout
        location={location}
        pageMetaTitle={title}
        pageMetaDescription={pageMetaDescription}
      >
        <BackpageBannerWrapper>
          <BackpageBanner
            fluid={hero_image.childImageSharp.fluid}
          ></BackpageBanner>
        </BackpageBannerWrapper>
        <Row>
          <Row10Col>
            <MainPageContent>
              <Row8Col>
                <HomeInfo>
                  <Details>
                    <PageTitle style={{ textAlign: 'left' }}>{title}</PageTitle>
                    <h2 style={{ marginTop: '1rem' }}>{price}</h2>

                    {sale_or_lease !== 'lease' && date_listed ? (
                      <small style={{ marginBottom: '0', display: 'block' }}>
                        Date Listed: {date_listed}
                      </small>
                    ) : null}

                    {sale_or_lease === 'lease' && date_available ? (
                      <small style={{ marginBottom: '0', display: 'block' }}>
                        Date Available: {date_available}
                      </small>
                    ) : null}

                    {sale_or_lease === 'lease' && rental_deposit ? (
                      <small style={{ marginBottom: '0', display: 'block' }}>
                        Deposit: {rental_deposit}
                      </small>
                    ) : null}

                    {realtor_name || realtor_email || cleanRealtorPhone
                      ? realtorInformation
                      : null}
                  </Details>
                  <FeaturedImageWrapper>
                    <Img fluid={featured_image.childImageSharp.fluid} />
                  </FeaturedImageWrapper>
                </HomeInfo>

                {html ? (
                  <hr style={{ margin: '1rem 0', background: '#d1d1d1' }} />
                ) : (
                  ''
                )}

                <div
                  // you need this inner-content class for the margin on the last child to get taken off
                  className="inner-content"
                  dangerouslySetInnerHTML={{
                    __html: html,
                  }}
                />
              </Row8Col>
            </MainPageContent>
          </Row10Col>
        </Row>
        <InterestedBuyers />
      </Layout>
    );
  }
}

export const SingleHomeQuery = graphql`
  query singleHomeByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        price
        date_listed
        date_available
        hero_image {
          childImageSharp {
            fluid(maxHeight: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        featured_image {
          childImageSharp {
            fluid(maxHeight: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        realtor_name
        realtor_email
        realtor_phone
        secondary_realtor_phone
        alt_realtor_name
        alt_realtor_email
        sale_or_lease
        rental_deposit
      }
    }
  }
`;
